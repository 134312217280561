import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import posthog from 'posthog-js'
import { FeatureFlagPayload, triggerInAppMessage } from '../utils'

const PageViewMessage = (): null => {
  const location = useLocation()

  useEffect(() => {
    const pageViewPayload = posthog.getFeatureFlagPayload(
      'show_in_app_message_page_view'
    ) as unknown as FeatureFlagPayload

    if (pageViewPayload && pageViewPayload.messages) {
      const pageMessage = pageViewPayload.messages[location.pathname]
      if (pageMessage) {
        triggerInAppMessage(pageMessage)
      }
    }
  }, [location])

  return null
}

export default PageViewMessage
