import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TooltipMessage from './message-components/tooltip-message'
import DialogMessage from './message-components/dialog-message'
import NotificationMessage from './message-components/notification-message'

const InAppMessaging: React.FC = () => {
  const messageToShow = useSelector((state: any) => state.ui.inAppMessage)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (messageToShow?.type === 'tooltip' && messageToShow.targetSelector) {
      // Find the target element dynamically using the provided data-in-app-message-id
      if (!messageToShow.anchorEl) {
        const element = document.querySelector(`[data-in-app-message-id="${messageToShow.targetSelector}"]`)
        if (element) {
          setAnchorEl(element as HTMLElement)
        }
      }
    }
  }, [messageToShow])

  const handleClose = () => {
    dispatch({
      type: 'CLEAR_IN_APP_MESSAGE',
    })
  }

  const handleButtonClick = (action: 'close' | 'navigate', url?: string) => {
    if (action === 'navigate' && url) {
      window.location.href = url
    }
    handleClose()
  }

  if (!messageToShow) return null

  return (
    <>
      {messageToShow.type === 'tooltip' && (
        <TooltipMessage
          anchorEl={anchorEl || messageToShow?.anchorEl}
          messageToShow={messageToShow}
          handleClose={handleClose}
          handleButtonClick={handleButtonClick}
        />
      )}
      {messageToShow.type === 'dialog' && <DialogMessage messageToShow={messageToShow} handleClose={handleClose} />}
      {messageToShow.type === 'notification' && (
        <NotificationMessage messageToShow={messageToShow} handleClose={handleClose} />
      )}
    </>
  )
}

export default InAppMessaging
