import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import { theme } from 'src/styling'
import { Loaders } from 'components'
import OrpheusLogo from 'src/images/orpheus/orpheus-logo-small.png'
import { Done } from '@material-ui/icons'
import { ReactComponent as Core } from 'src/images/icons/subscription-core.svg'
import { ReactComponent as Enterprise } from 'src/images/icons/subscription-enterprise.svg'
import { ReactComponent as Professional } from 'src/images/icons/subscription-professional.svg'
import { ReactComponent as Custom } from 'src/images/icons/subscription-custom.svg'
import { ReactComponent as Recommended } from 'src/images/icons/subscription-recommended.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getJwtPayload } from 'src/auth/payload'
import {
  recordUserInteraction,
  selfUpgradeModalOpen,
  userSelectedPackage,
  userSubmittedSelectedPackage,
} from 'src/services/posthog'
import ReactTooltip from 'react-tooltip'
import snackbar from 'src/context/snackbar'
import axios from 'axios'

// ======
// TYPES
// ======
type SubscriptionInfoPageProps = {
  handleRequestCheckout: (
    productName: string,
    productId: string,
    priceId: string,
    subscriptionPrice: number
  ) => Promise<void>
  loadingClientSecret: object
}

type StyleProps = { showPartnerBranding: boolean; trialPeriodLength: number; header?: string }

const { userId, subscriptionPlan } = getJwtPayload()
// ======
// STYLES
// ======
const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.ui.blueDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subscriptionSelectContainer: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.ui.blueDark,
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: props.showPartnerBranding ? '0.5rem 3rem' : '0.5rem 1rem',
    margin: props.showPartnerBranding ? '1rem 1rem' : '0.5rem',
    fontFamily: '"Montserrat", sans-serif !important',
  }),
  subscriptionBox: {
    flex: '1 1 300px', // Flex-grow, flex-shrink, flex-basis
    minWidth: '300px', // Ensures minimum width
    position: 'relative', // For positioning the badge
    boxShadow: 'none !important',
    color: theme.colors.ui.blueLight,
    fontSize: theme.fontSizes.mediumLarge,
  },
  cardContent: {
    borderRadius: '5px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem !important',
  },
  nonRecommended: {
    display: 'content',
    marginTop: '3rem',
  },
  recommended: {
    border: `2px solid ${theme.colors.ui.grey}`,
    boxShadow: `0 0 10px ${theme.palette.primary.main}`,
    paddingTop: '2rem',
    backgroundColor: theme.colors.ui.grey,
    marginTop: '0.9rem',
  },
  recommendedBadge: {
    fontSize: theme.fontSizes.xLarge,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#fff',
    top: '2%',
    borderRadius: '5px',
    textAlign: 'center',
    justifySelf: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  subscriptionSelectHeaderContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    width: '100%',
    padding: '0 10px',
  },
  subscriptionSelectHeader: { fontSize: theme.fontSizes.xxLarge },
  subscriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  loaderContainer: {
    minHeight: '400px',
    minWidth: '400px',
    alignItems: 'center',
    display: 'flex',
  },
  lowerContainer: {
    backgroundColor: theme.colors.ui.white,
    borderRadius: '5px',
    maxWidth: '300px',
  },
  partnerBrandingContainer: {
    gridColumn: '4/5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  partnerBrandingHeader: { fontSize: theme.fontSizes.mediumLarge, fontWeight: 'lighter' },
  brandingLogo: { width: '160px', height: '35px' },
  selfSignUpInfoContainer: (props: StyleProps) => ({
    gridColumn: '1',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'center',
    gap: '.5rem',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    ...(props.showPartnerBranding
      ? {
          marginLeft: '20%',
        }
      : {}),
  }),
  orpheusLogo: {
    height: '30px',
  },
  logoSpan: {
    fontFamily: '"Montserrat", sans-serif',
    color: theme.colors.ui.blue,
    fontWeight: 'bold',
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  selfSignUpInfoTitle: {
    textAlign: 'center',
    fontSize: theme.fontSizes.xLarge,
    // padding: '0.6rem',
    paddingTop: 0,
    fontFamily: '"Montserrat", sans-serif',
    color: theme.colors.ui.blueLight,
  },
  chooseSubscriptionContainer: {
    minHeight: '570px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.ui.grey}`,
    borderRadius: '5px',
    padding: '0.4rem 1.5rem',
  },
  chooseSubscriptionHeader: {
    paddingTop: '2%',
    textAlign: 'center',
    fontWeight: 'bold',
    justifySelf: 'center',
    display: 'flex',
    fontSize: theme.fontSizes.mediumLarge,
    alignSelf: 'center',
    gap: '0.2rem',
    fontFamily: '"Montserrat", sans-serif !important',
  },
  accessInfo: (props) => ({
    gridTemplateRows: 'auto 1fr',
    gap: '0.6rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: props.header === 'Core' ? '0.5rem' : '3px',
    fontSize: '13px',
    fontFamily: '"Montserrat", sans-serif !important',
  }),
  accessInfoDisabled: {
    gridTemplateRows: 'auto 1fr',
    gap: '0.6rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.ui.greyLight,
    paddingLeft: '1.8rem',
    paddingTop: '0.5rem',
  },
  subscriptionInfoContainer: { flexGrow: 1, marginTop: '2%' },
  priceInfoContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
  discountedPrice: {
    marginBottom: '0.5rem !important',
  },
  checkoutBtnContainer: (props: StyleProps) => ({
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    alignItems: props.trialPeriodLength ? 'center' : 'end',
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    ...(props.trialPeriodLength
      ? {
          justifyContent: 'end',
          flexDirection: 'column',
          gap: '0.4rem',
        }
      : {}),
  }),
  checkoutBtn: {
    width: '160px',
    height: '35px',
    textTransform: 'none',
    fontFamily: '"Montserrat", sans-serif !important',
    '&:hover': {
      color: theme.colors.ui.white,
    },
  },
  originalPrice: { display: 'inline-block', position: 'relative' },
  originalPriceSpan: { fontSize: theme.fontSizes.medium },
  priceStrikeThrough: {
    '&::after': {
      content: '""',
      top: '40%',
      left: '-5%',
      width: '110%',
      position: 'absolute',
      borderBottom: `solid 2px ${theme.colors.ui.blueDark}`,
    },
  },
  discountedPriceSpan: { fontSize: '20px', fontWeight: 'normal' },
  pageListHeader: {
    display: 'flex',
    color: theme.colors.ui.grey,
    fontSize: theme.fontSizes.mediumLarge,
    marginTop: '0.5rem',
    marginBottom: '0.2rem',
    justifyContent: 'center',
  },
  pageList: { display: 'flex', flexDirection: 'column', alignItems: 'left', minHeight: '120px' },
  dropdownStyles: { '& .MuiOutlinedInput-input': { padding: '12.5px 14px', color: theme.colors.ui.blueDark } },
  subscriptionTermsText: {
    fontSize: theme.fontSizes.small,
    color: theme.colors.ui.grey,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  bottomPageList: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.ui.greyVeryLight,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '0.5rem',
    minWidth: '240px',
  },
  userAndDomains: {
    paddingTop: '0.5rem',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gridTemplateRows: 'auto auto',
    fontSize: '13px',
  },
  leftAlign: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    flex: 4,
    position: 'absolute',
  },
  rightAlign: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
    paddingRight: '2px',
    flex: 2,
  },
  subscriptionFooter: {
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '2rem',
  },
})

// ==========
// FUNCTIONS
// ==========
const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
})

const subscriptionRanks = {
  core: 1,
  professional: 2,
  enterprise: 3,
}
const getSubmitButtonText = (loadingClientSecret?: Object, trialPeriodLength: number, subscriptionType: string) => {
  if (loadingClientSecret[subscriptionType]) return <Loaders.Spinner size={25} />
  if (trialPeriodLength) return 'Start free trial'
  if (subscriptionType.toLowerCase() === 'bespoke') return 'Talk to us'
  const currentRank = subscriptionRanks[subscriptionPlan?.type]
  const targetRank = subscriptionRanks[subscriptionType.toLowerCase()]

  if (targetRank < currentRank) return 'Talk to us'
  if (targetRank > currentRank) return 'Upgrade'
  return 'Go to payment'
}

export const getIcon = (header: string) => {
  switch (header) {
    case 'Core':
      return <Core />
    case 'Professional':
      return <Professional />
    case 'Enterprise':
      return <Enterprise />
    case 'Bespoke':
      return <Custom />
    default:
      return null
  }
}

const getPackageInfo = (productName: string) => {
  switch (productName) {
    case 'Core':
      return [
        'Cyber Threat Intelligence',
        'Cyber Risk Rating',
        'External Attack Surface Management',
        'Internal Risk Based Vulnerability Management',
        'Supply Chain Risk Management',
      ]
    case 'Professional':
      return [
        'Alerts',
        'Attack Surface Visualisation',
        'Premium Scanning',
        'Configure IP Ranges',
        'Data Exports',
        'Digital Risk Protection',
        'View As Third-party',
        'TPRM Groups',
        'TPRM Concentration Risk',
        'CTI Summary Reporting',
        'Search For & Download IoCs',
      ]
    case 'Enterprise':
      return [
        'Privacy Risk Protection',
        'RBVM Integration',
        'Multitenancy',
        'Self Attestation',
        'API',
        'SSO',
        'CRR Custom Branding',
        'Collaboration',
        'User Access Management',
      ]
    case 'Bespoke':
      return ['Managed service options', 'Tailored to fit your use case', 'More payment options']
    default:
      return null
  }
}

const getUserAndDomains = (header, classes) => {
  let userAndDomains: {}
  switch (header) {
    case 'Core':
      userAndDomains = {
        Users: 1,
        'Third-party companies': 2,
        'Configured domains': 1,
        'RBVM hosts': 10,
        'Intelligence reports': '14 Days',
      }
      break
    case 'Professional':
      userAndDomains = {
        Users: 2,
        'Third-party companies': 5,
        'Configured domains': 4,
        'RBVM hosts': 100,
        'Intelligence reports': '90 Days',
      }
      break
    case 'Enterprise':
      userAndDomains = {
        Users: 4,
        'Third-party companies': 15,
        'Configured domains': 8,
        'RBVM hosts': 500,
        'Intelligence reports': '365 Days',
      }
      break
    case 'Bespoke':
      userAndDomains = {
        Users: 'Custom',
        'Third-party companies': 'Custom',
        'Configured domains': 'Custom',
        'RBVM hosts': 'Custom',
        'Intelligence reports': 'Custom',
      }
      break
    default:
      userAndDomains = {}
  }
  return Object.keys(userAndDomains).map((info, index) => (
    <Box key={index} className={classes.userAndDomains}>
      <div className={classes.leftAlign}>{info}</div>
      <div className={classes.rightAlign}>{userAndDomains[info]}</div>
    </Box>
  ))
}
const SubscriptionBox = ({
  header,
  productId,
  handleRequestCheckout,
  subscriptionPrice,
  subscriptionPriceId,
  loadingClientSecret,
  getSubmitButtonText,
  trialPeriodLength,
  couponPercentOff,
  isRecommended,
}) => {
  const classes = useStyles({ header: header })
  const buttonText = getSubmitButtonText(loadingClientSecret, trialPeriodLength, header)
  return (
    <Card
      className={`${classes.subscriptionBox} ${
        header.toLowerCase() === subscriptionPlan?.type
          ? classes.recommended
          : isRecommended
          ? classes.recommended
          : classes.nonRecommended
      }`}
    >
      {(!subscriptionPlan?.type || header.toLowerCase() === subscriptionPlan?.type) && (
        <Box className={classes.recommendedBadge}>
          {header.toLowerCase() === subscriptionPlan?.type ? 'Current Subscription' : null}
          {!subscriptionPlan?.type && isRecommended && header.toLowerCase() !== subscriptionPlan?.type && (
            <Recommended />
          )}
          {!subscriptionPlan?.type &&
            isRecommended &&
            header.toLowerCase() !== subscriptionPlan?.type &&
            'Most Popular'}
        </Box>
      )}
      <CardContent className={classes.cardContent}>
        <Box className={classes.lowerContainer}>
          <Box className={classes.chooseSubscriptionContainer}>
            <Typography className={classes.chooseSubscriptionHeader}>
              {getIcon(header)}
              {header}
            </Typography>
            <Box className={classes.subscriptionInfoContainer}>
              <Box className={classes.priceInfoContainer}>
                {/* <p className={`${classes.originalPrice} ${classes.priceStrikeThrough}`}>
              <span className={classes.originalPriceSpan}>
                {formatter.format(subscriptionPrice).replace('.00', '')}
              </span>
              /{priceTimeText}
            </p> */}
                <p className={classes.discountedPrice}>
                  <span className={classes.discountedPriceSpan}>
                    {header === 'Bespoke' ? 'Talk to us' : `£${subscriptionPrice}`}
                  </span>
                  {header !== 'Bespoke' && ` / month`}
                </p>
              </Box>
              <Box className={classes.bottomPageList}>{getUserAndDomains(header, classes)}</Box>
              <Box className={classes.pageListContainer}>
                <p className={classes.pageListHeader}>
                  {header === 'Professional' ? (
                    'Everything in Core plus:'
                  ) : header === 'Enterprise' ? (
                    'Everything in Professional plus:'
                  ) : (
                    <br />
                  )}
                </p>
                <Box className={classes.pageList}>
                  {getPackageInfo(header).map((info, index) => (
                    <Box key={index} className={classes.accessInfo}>
                      {
                        <>
                          <Done color="secondary" />
                          {info}
                        </>
                      }
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.checkoutBtnContainer}>
              {header.toLowerCase() !== subscriptionPlan?.type && (
                <Button
                  data-for={buttonText === 'Talk to us' && header !== 'Bespoke' ? `tooltip-downgrade` : undefined}
                  data-tip={buttonText === 'Talk to us' && header !== 'Bespoke' ? true : false}
                  variant="contained"
                  color="primary"
                  className={classes.checkoutBtn}
                  onClick={
                    //</Box> buttonText === 'Upgrade' ?  ()=> handleConfirmationModalOpen(true) :
                    buttonText === 'Talk to us' || header === 'Bespoke'
                      ? recordUserInteraction(
                          userId ? userSelectedPackage(header) : userSubmittedSelectedPackage(header)
                        )
                      : () => handleRequestCheckout(header, productId, subscriptionPriceId, subscriptionPrice)
                  }
                  disabled={header !== 'Bespoke' && loadingClientSecret[header]}
                  href={(buttonText === 'Talk to us' || header === 'Bespoke') && 'mailto:sales@orpheus-cyber.com'}
                >
                  {buttonText}
                </Button>
              )}
              {buttonText === 'Talk to us' ||
                (header !== 'Bespoke' && (
                  <ReactTooltip
                    backgroundColor={theme.colors.ui.blueDark}
                    id={`tooltip-downgrade`}
                    place="top"
                    effect="solid"
                  >
                    Please contact us to Downgrade
                  </ReactTooltip>
                ))}
              {trialPeriodLength ? (
                <span className={classes.subscriptionTermsText}>
                  {`In ${trialPeriodLength} days you will be prompted to confirm your subscription`} <br /> No credit
                  card details required for the trial
                </span>
              ) : null}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
// =========
// COMPONENT
// =========
const SubscriptionInfoPage = ({ handleRequestCheckout, loadingClientSecret }: SubscriptionInfoPageProps) => {
  const { subscriptionPackagesInfo } = useSelector((state) => state.ui)

  const showPartnerBranding = !!subscriptionPackagesInfo.partnerBrandingLogoUrl

  const trialPeriodLength = subscriptionPackagesInfo.trialPeriodLength
  const classes = useStyles({ showPartnerBranding, trialPeriodLength })
  const { enqueueSnackbar } = snackbar.Consumer()
  const dispatch = useDispatch()
  const [isUpgradeModalLoading, setIsUpgradeModalLoading] = useState(false)
  const [data, setData] = useState({ customerId: null, email: '', products: [] })

  const fetchData = async () => {
    setIsUpgradeModalLoading(true)
    try {
      const response = await axios.post('/payment-portal-lite/products', {
        userId,
      })
      setData({
        customerId: response?.data?.customerId,
        email: response?.data?.email,
        products: response?.data?.products,
      })
      dispatch({
        type: 'SUBSCRIPTION_PACKAGES_INFO',
        payload: {
          customerId: response?.data?.customerId,
          email: response?.data?.email,
          products: response?.data?.products,
        },
      })
    } catch (error) {
      console.log('handle submit code and email error: ', error)
      if (
        error?.response?.data?.message === `Please contact us at support@orpheus-cyber.com to upgrade` ||
        error?.response?.data?.message === 'Referral code not recognised'
      ) {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'error',
        })
      } else
        enqueueSnackbar('Something went wrong, please try again later', {
          variant: 'error',
        })
    } finally {
      setIsUpgradeModalLoading(false)
    }
  }
  useEffect(() => {
    if (userId) {
      fetchData()
    }
  }, [userId])
  const products = userId ? data?.products : subscriptionPackagesInfo?.products // if its redeem flow
  return (
    <Box className={!userId && classes.container}>
      <Box className={classes.subscriptionSelectContainer}>
        {/* Commenting out the partner branding for the first version of the self sign up features */}
        <Box className={classes.subscriptionSelectHeaderContainer}>
          <Box className={classes.selfSignUpInfoContainer}>
            <div className={classes.logoSpan}>
              <img src={OrpheusLogo} className={classes.orpheusLogo} />
              <span>ORPHEUS PORTAL</span>
            </div>
            <div className={classes.selfSignUpInfoTitle}>Choose your subscription</div>
          </Box>
          {showPartnerBranding ? (
            <Box className={classes.partnerBrandingContainer}>
              <Typography className={classes.partnerBrandingHeader}>Orpheus Cyber in partnership with</Typography>
              <img src={subscriptionPackagesInfo.partnerBrandingLogoUrl} className={classes.brandingLogo} />
            </Box>
          ) : null}
        </Box>
        {isUpgradeModalLoading ? (
          <Box className={classes.loaderContainer}>
            <Loaders.Spinner size={30} />
          </Box>
        ) : (
          products.length > 0 && (
            <Box className={classes.subscriptionContainer}>
              {products.map((product) => (
                <SubscriptionBox
                  header={product?.description}
                  productId={product?.productId}
                  subscriptionPrice={product?.monthlySubscriptionPrice}
                  subscriptionPriceId={product?.monthlyPriceId}
                  handleRequestCheckout={handleRequestCheckout}
                  loadingClientSecret={loadingClientSecret}
                  getSubmitButtonText={getSubmitButtonText}
                  trialPeriodLength={subscriptionPackagesInfo?.trialPeriodLength}
                  couponPercentOff={subscriptionPackagesInfo?.couponPercentOff}
                  isRecommended={
                    subscriptionPlan?.type
                      ? false
                      : product?.description.toLowerCase() !== subscriptionPlan?.type &&
                        product?.description?.toLowerCase() === 'professional'
                  }
                />
              ))}
              <SubscriptionBox
                header="Bespoke"
                handleRequestCheckout={handleRequestCheckout}
                loadingClientSecret={false}
                getSubmitButtonText={getSubmitButtonText}
                isRecommended={false}
              />
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}

export default SubscriptionInfoPage
