import React from 'react'
import { IconButton } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'

interface NotificationMessageProps {
  messageToShow: any
  handleClose: () => void
}

const NotificationMessage: React.FC<NotificationMessageProps> = ({ messageToShow, handleClose }) => {
  if (messageToShow.type !== 'notification') return null
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const textElement = messageToShow.content?.find((el) => el.type === 'text')
  if (textElement) {
    enqueueSnackbar(textElement.text, {
      autoHideDuration: 15000,
      ContentProps: { style: { backgroundColor: '#03123E' } },
      onClose: handleClose,
      action: (key) => (
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key)
            handleClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
  }

  return null
}

export default NotificationMessage
