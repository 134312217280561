import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, makeStyles } from '@material-ui/core'
import { OnboardingWelcomeDialog } from '.'

interface WelcomeDialogProps {
  open: boolean
  onStartOnboarding: () => void
  onSkipOnboarding: () => void
  content: OnboardingWelcomeDialog
}
const defaultWelcomeOnboardingContent = {
  title: 'Welcome to orpheus',
  greetMessage:
    "Welcome to Orpheus! We're here to guide you through the key features that will help you get the most out of our platform",
  promptMessage: 'Would you like to start the onboarding process now, or would you prefer to do it later?',
}

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '20px',
  },
  dialogContent: {
    padding: '20px',
  },
  dialogActions: {
    padding: '12px 20px',
  },
}))

const WelcomeDialog: React.FC<WelcomeDialogProps> = ({ open, onStartOnboarding, onSkipOnboarding, content }) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onSkipOnboarding} classes={{ paper: classes.dialog }}>
      <DialogTitle>{content?.title || defaultWelcomeOnboardingContent.title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">{content?.greetMessage || defaultWelcomeOnboardingContent.greetMessage}</Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
          {content?.promptMessage || defaultWelcomeOnboardingContent.promptMessage}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onSkipOnboarding} color="primary">
          Later
        </Button>
        <Button onClick={onStartOnboarding} color="primary" variant="contained">
          Start Onboarding
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WelcomeDialog
