import React, { useState, useEffect } from 'react'
import WelcomeDialog from './welcome-dialog'
import OnboardingMessage from './onboarding-message'
import posthog from 'posthog-js'

export type OnboardingWelcomeDialog = {
  title: string
  greetMessage: string
  promptMessage: string
}
export type OnboardingStep = {
  selector: string
  title: string
  content: string
  placement: 'top' | 'bottom' | 'left' | 'right'
}
type OnboardingFeatureFlagPayload = {
  onboardingSteps: Array<OnboardingStep>
  welcomeDialog: OnboardingWelcomeDialog
}

const OnboardingManager: React.FC = () => {
  const [onboardingStarted, setOnboardingStarted] = useState(false)
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(true)
  const [onboardingPayload, setOnboardingPayload] = useState<OnboardingFeatureFlagPayload | null>(null)

  useEffect(() => {
    if (onboardingStarted) {
      setShowWelcomeDialog(false)
    }
  }, [onboardingStarted])

  useEffect(() => {
    // Wait for PostHog to load feature flags
    posthog.onFeatureFlags(() => {
      const payload = posthog.getFeatureFlagPayload('show_onboarding_steps') as OnboardingFeatureFlagPayload

      setOnboardingPayload(payload)
    })
  }, [])

  if (!onboardingPayload?.onboardingSteps?.length) return null

  const onboardingSteps = onboardingPayload.onboardingSteps
  const onboardingDialogContent = onboardingPayload.welcomeDialog

  // Start onboarding when the user accepts
  const handleStartOnboarding = () => {
    setOnboardingStarted(true)
    setShowWelcomeDialog(false)
  }

  const handleSkipOnboarding = () => {
    setShowWelcomeDialog(false)
  }

  // Close onboarding when finished
  const handleOnboardingClose = () => {
    setOnboardingStarted(false)
  }

  return (
    <>
      {/* Shows WelcomeDialog if onboarding hasn't been started */}
      {!onboardingStarted && showWelcomeDialog && (
        <WelcomeDialog
          open={showWelcomeDialog}
          onStartOnboarding={handleStartOnboarding}
          onSkipOnboarding={handleSkipOnboarding}
          content={onboardingDialogContent}
        />
      )}

      {/* Manages onboarding steps */}
      {onboardingStarted && <OnboardingMessage steps={onboardingSteps} onClose={handleOnboardingClose} />}
    </>
  )
}

export default OnboardingManager
