import posthog from 'posthog-js'
import { rootStore } from 'src/redux/store'

// Define the in-app message payload structure
export type ContentElement = TextElement | ButtonElement | LinkElement

interface TextElement {
  type: 'text'
  text: string
}

interface ButtonElement {
  type: 'button'
  text: string
  action: 'close' | 'navigate'
  url?: string
}

interface LinkElement {
  type: 'link'
  text: string
  url: string
}
export type FeatureFlagPayload = {
  messages: {
    [key: string]: InAppMessagePayload
  }
}
export type InAppMessagePayload = {
  id: string
  type: 'dialog' | 'tooltip' | 'notification'
  dialogTitle?: string
  targetId?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  content: ContentElement[]
}

export const checkInteractionAndTriggerMessage = (interactionName: string) => {
  const interactionPayload = posthog.getFeatureFlagPayload(
    'show_in_app_message_user_interaction'
  ) as unknown as FeatureFlagPayload

  if (interactionPayload && interactionPayload.messages) {
    const interactionMessage = interactionPayload.messages[interactionName]

    if (interactionMessage) {
      // If the message type is a tooltip, find the element using targetId and construct the selector
      if (interactionMessage.type === 'tooltip' && interactionMessage.targetId) {
        const selector = `[data-in-app-message-id='${interactionMessage.targetId}']`
        const element = document.querySelector(selector)

        if (element) {
          interactionMessage.anchorEl = element as HTMLElement
        }
      }

      // Trigger the appropriate in-app message (dialog, tooltip, notification)
      triggerInAppMessage(interactionMessage)
    }
  }
}
export const triggerInAppMessage = (message: InAppMessagePayload) => {
  const shownMessages: string[] = JSON.parse(localStorage.getItem('shownMessages') || '[]')
  if (!shownMessages.includes(message.id)) {
    rootStore.dispatch({
      type: 'SET_IN_APP_MESSAGE',
      payload: message,
    })
    shownMessages.push(message.id)
    localStorage.setItem('shownMessages', JSON.stringify(shownMessages))
  }
}
